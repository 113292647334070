<template>
  <div>
    <div>
      <el-button type="primary" @click="print">打印</el-button>
    </div>
    <tinymce id="tinymce" ref="tinymceRefs" :texts="html" @changes="getChangeData"></tinymce>
    <div id="forms" style="width: 2100px;font-size: 13.7px!important;">
      <div v-html="html">
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from "@/components/tinymce";
import print from "print-js";

export default {
  name: "liveEstate",
  components: {
    tinymce
  },
  data() {
    return {
      check1: false,
      check2: false,
      check3: false,
      check4: false,
      check5: false,
      check6: false,
      check7: false,
      check8: false,
      check9: false,
      check10: false,
      check11: false,
      check12: false,
      check13: false,
      check14: false,
      check15: false,
      check16: false,
      check17: false,
      check18: false,
      check19: false,
      check20: false,
      check21: false,
      check22: false,
      check23: false,
      html: '<p style="text-align: center;"><span style="text-align: center;">XXX地块规划设计要求（单一居住用地：指居住街坊配套的各类服务设施计容建筑面积小于10%）</span></p>\n' +
          '<table style="border-collapse: collapse; width: 102.441%; height: 1627px;" border="1">\n' +
          '<tbody>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 100%; text-align: center; height: 13px;" colspan="3"><span style="text-align: center;">本地块的建设必须严格按图表所示，若有改动需征得自然资源和规划主管部门认可</span></td>\n' +
          '</tr>\n' +
          '<tr style="height: 245px;">\n' +
          '<td style="width: 48.8905%; height: 159px;">\n' +
          '<p>一、区域位置及条件:</p>\n' +
          '<p>&nbsp; &nbsp; 1.用地位置:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>总用地面积<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>公顷。其中:出让用地面积&nbsp;<span style="text-decoration: underline;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>公顷，<input id="1" type="checkbox" />城市道路用地面积<span style="text-decoration: underline;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>公顷，<input id="2" type="checkbox" />城市河道用地面积<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>公顷，<input id="3" type="checkbox" />城市公共绿地面积<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>公顷。城市城市道路、城市河道、城市公共绿地须由建设单位统一实施后无偿移交政府相关主管部门管理。</p>\n' +
          '<p>&nbsp; &nbsp; 2.地块及周边现状:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>。</p>\n' +
          '<p>&nbsp; &nbsp; 3.地块周边规划情况:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>。</p>\n' +
          '<p>二、规划控制指标:</p>\n' +
          '<p>&nbsp; &nbsp; 1.规划用地性质:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>，代码:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>（其他住宅用地如Rra、Rrc等应明确比例内容及要求)。规划房屋用途为住宅及相关配套服务设施。</p>\n' +
          '<p>&nbsp; &nbsp; 2.经济技术指标: <span style="text-decoration: underline;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>。</p>\n' +
          '<p>3.加强规划设计方案总体布局研究，合理划分地块，分地块容积率对应的系列指标符合《城市居住区规划设计标准》（GB50180-2018)。</p>\n' +
          '<p>4.其它控制要求:居住用地的配套商业用房，如需设置餐饮用房的，要明确餐饮用房位置及专用烟道位置，且餐饮用房不得与居住层相邻。</p>\n' +
          '<p>三、地下空间</p>\n' +
          '<p>&nbsp; &nbsp; 1.合理开发利用地下空间。地下空间规划用途为：<input id="4" type="checkbox" />人防、<input id="5" type="checkbox" />地下停车设施、<input id="6" type="checkbox" />仓储藏室等。地下空间水平可利用最大范围及退界详见附图。</p>\n' +
          '<p>&nbsp; &nbsp;<input id="7" type="checkbox" />统筹利用相邻地块地下空间，相关地下空间连通要求详见附图。周边地下空间权属情况:&nbsp; &nbsp; &nbsp;<span style="text-decoration: underline;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>。</p>\n' +
          '<p>&nbsp; &nbsp;<input id="8" type="checkbox" />与地铁合理衔接，采取有效措施确保轨道交通设施安全和运营安全。</p>\n' +
          '<p>&nbsp; &nbsp; 2.地下空间利用要求:应符合《宁波市地下空间开发利用管理办法》(市政府令第230号)、《宁波市地下空间开发利用管理实施细则(试行》)(甬政办发{2018} 99号)、《宁波市城乡规划管理技术规定》 (甬政发{2014} 74号)。同时满足周边地块结构安全、施工安全、管线布局、运行安全等要求。</p>\n' +
          '<p>&nbsp; &nbsp; 3.允许开发使用范围内的地下空间主体结构竖向利用深度为-<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>米以上(1985国家高程基准)。</p>\n' +
          '<p>&nbsp; &nbsp; 坡地项目高差变化比较大,以地块场地地坪标高（地块内主要道路）为基准明确可利用深度。</p>\n' +
          '<p>&nbsp; &nbsp; 4.人防工程:按照新建地面总建筑面积的<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; %</span>核定人防工程面积，本地块人防工程配建指标为<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>平方米/人。 人防设施的建设和配建要求应符合宁波市人民政府办公厅《关于推进人民防空规划建设融入城市规划的实施意见》(甬政办发 {2018} 101 号)及《关于明确建设项目落实人防设施设置要求的通知》 (甬人防办通{2018} 25号)等文件规定及现行人防政策。</p>\n' +
          '<p>四、配套设施</p>\n' +
          '<p>&nbsp; &nbsp; 1.配件设施表</p>\n' +
          '<table style="border-collapse: collapse; width: 99.0875%; height: 136px; margin-left: auto; margin-right: auto;" border="1">\n' +
          '<tbody>\n' +
          '<tr style="height: 42px;">\n' +
          '<td style="width: 20%; height: 42px; text-align: center;">配套设施名称</td>\n' +
          '<td style="width: 20%; height: 42px; text-align: center;">数量</td>\n' +
          '<td style="width: 20%; height: 42px; text-align: center;">用地面积(<span style="text-align: center;">㎡</span>）</td>\n' +
          '<td style="width: 20%; height: 42px; text-align: center;">建筑面积（<span style="text-align: center;">㎡</span>)</td>\n' +
          '<td style="width: 19.0881%; height: 42px;">其它要求（配套标准、结建等)</td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">幼儿园</td>\n' +
          '<td style="width: 20%; height: 21px;">\n' +
          '<p style="text-align: center;">1</p>\n' +
          '</td>\n' +
          '<td style="width: 20%; height: 21px;">\n' +
          '<p style="text-align: center;"><input id="9" type="checkbox" />不小于4655</p>\n' +
          '<p style="text-align: center;"><input id="10" type="checkbox" />不小于6010</p>\n' +
          '<p style="text-align: center;"><input id="11" type="checkbox" />不小于7511</p>\n' +
          '</td>\n' +
          '<td style="width: 20%; height: 21px;">\n' +
          '<p style="text-align: center;"><input id="12" type="checkbox" />不小于3187</p>\n' +
          '<p style="text-align: center;"><input id="13" type="checkbox" />不小于4042</p>\n' +
          '<p style="text-align: center;"><input id="14" type="checkbox" />不小于5054</p>\n' +
          '</td>\n' +
          '<td style="width: 19.0881%; height: 21px;">\n' +
          '<p style="text-align: center;"><input id="15" type="checkbox" />9班</p>\n' +
          '<p style="text-align: center;"><input id="16" type="checkbox" />12班</p>\n' +
          '<p style="text-align: center;"><input id="17" type="checkbox" />15班</p>\n' +
          '</td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">物业经营用房</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">1</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">&nbsp;</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">&nbsp;</td>\n' +
          '<td style="width: 19.0881%; height: 21px;">按地上住宅总建筑面积的4%</td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">物业管理用房</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">1</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">&nbsp;</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">&nbsp;</td>\n' +
          '<td style="width: 19.0881%; height: 21px;">按地上住宅总建筑面积的3%</td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">社区配套用房</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">1</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">&nbsp;</td>\n' +
          '<td style="width: 20%; height: 21px; text-align: center;">&nbsp;</td>\n' +
          '<td style="width: 19.0881%; height: 21px;">60平方米每百户(含20平方米每百户居家养老用房)</td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 20%; height: 10px; text-align: center;">室内体育场馆</td>\n' +
          '<td style="width: 20%; height: 10px; text-align: center;">1</td>\n' +
          '<td style="width: 20%; height: 10px; text-align: center;">&nbsp;</td>\n' +
          '<td style="width: 20%; height: 10px; text-align: center;">\n' +
          '<p><input id="18" type="checkbox" />不小于250</p>\n' +
          '<p><input id="19" type="checkbox" />不小于350</p>\n' +
          '</td>\n' +
          '<td style="width: 19.0881%; height: 10px;"><input id="20" type="checkbox" />不小于地上住宅总建筑面积的5%</td>\n' +
          '</tr>\n' +
          '</tbody>\n' +
          '</table>\n' +
          '<p>2.地块内按规范配置市政、消防、供电、供水、燃气、环卫（含垃圾分类收集房）、社区、物业、体育健身等配套设施,并与本地块同步设计、同步建设、同步验收、同步交付使用。</p>\n' +
          '<p>3.对分期建设的项目，相关配套设施先行。</p>\n' +
          '<p>五、城市设计要求</p>\n' +
          '<p>&nbsp; &nbsp; (一) 强制性要求</p>\n' +
          '<p>&nbsp; &nbsp; 1.建筑退界和间距:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>米。建筑退线详见附图(包括退让边界、道路、河道、管线安全距离等)，建筑间距按《宁波市城乡规划管理技术规定》(甬政发{2014}74号)执行。</p>\n' +
          '<p>&nbsp; &nbsp; 2.建筑高度:____米。 (对机场净空、 军事管理等有特殊要求的地块建筑高度应明确建筑的最高点高度，三名保护范围周边应明确建筑的屋檐、 屋脊高度的控制要求。)</p>\n' +
          '<p>&nbsp; &nbsp; 3.公共空间:主要指各种公共廊道，公共开放空间。</p>\n' +
          '<p>&nbsp; &nbsp; 4.公共界面:沿主要道路、城市节点的界面建筑控制，如贴线率、街墙比等。</p>\n' +
          '<p>&nbsp; &nbsp; 5.文物保护:地块位于历史文化街区、地段的核心保护范围或者建设控制地带内的，应同步注明保护要求。</p>\n' +
          '<p>&nbsp; &nbsp; 6.轨道站点一体化: 地块轨道交通控制线范围内的建设应符合轨道交通控制线相应管理要求。</p>\n' +
          '</td>\n' +
          '<td style="width: 1.10947%; height: 159px;">\n' +
          '<p>&nbsp;</p>\n' +
          '</td>\n' +
          '<td style="width: 50%; height: 159px;">&nbsp;(二)指导性要求\n' +
          '<p>&nbsp; &nbsp; 1.空间布局:包括建筑组合、建筑体量、建筑高度变化等。如:当建筑高度不大于27米时，建筑面宽应控制在70米以下:当建筑高度不大于36米时，建筑面宽应控制在60米以下:当建筑高度不大于54米时，建筑面宽应控制在50米以下:当建筑高度不大于80米时，建筑面宽应控制在42米以下。建筑高度54米及以上的住宅沿路或沿城市界面连续布置3栋以上，应有高度变化，且高度变化不少于较高建筑的1/5</p>\n' +
          '<p>&nbsp; &nbsp; 2.细部设计:建筑色彩及材质、第五立面、空调机位、广告标牌、照明灯光等。</p>\n' +
          '<p>&nbsp; &nbsp; 3.街道景观:对景观环境的指引，如绿化、 环境艺术等。</p>\n' +
          '<p>&nbsp;(三)其他</p>\n' +
          '<p>&nbsp; &nbsp; <input id="21" type="checkbox" />本项目规划设计方案需报宁波市建筑环境与文化艺术委员会审议。</p>\n' +
          '<p>&nbsp; &nbsp; <input id="22" type="checkbox" />本项目规划设计方案需同步提交景观灯光规划设计方案。</p>\n' +
          '<p>&nbsp; &nbsp; <input id="23" type="checkbox" />本项目规划设计方案提供三个相应设计资质单位的三个方案，多方案比较并组织专家论证。</p>\n' +
          '<p>六、道路交通</p>\n' +
          '<p>&nbsp; &nbsp; 1. 道路系统:地块周边道路<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>；机动车主要出入口位于<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>如图所示，出入口宽度:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>。禁开口段为<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>。地块规划要求包括新增支路、通道、步行系统、二层连廊、停车设施等。</p>\n' +
          '<p>&nbsp; &nbsp; 2合理组织车流、人流和车辆停放，妥善处理好地块内外部交通的关系，减少对城市交通影响。</p>\n' +
          '<p>&nbsp; &nbsp; 3.机动车位配建指标及要求:&nbsp;<span style="text-decoration: underline;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>；非机动车配建指标及要求:&nbsp;<span style="text-decoration: underline;">&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>。社会停车位配建指标及要求:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>。</p>\n' +
          '<p>七、市政依托</p>\n' +
          '<p>&nbsp; &nbsp; 1.地块的沿路退界、沿河开敞空间等，如需设置市政公用管线及轨道四小件等设施的，应无偿提供相应空间，并为其维修保养提供必备条件。</p>\n' +
          '<p>&nbsp; &nbsp; 2.地块市政管线依托周边道路，井与周边地块相衔接，各类市政管线设计由用地单位向相关专业单位衔接。</p>\n' +
          '<p>八、竖向规划</p>\n' +
          '<p>&nbsp; &nbsp; 1.本地块根据周边道路规划高程作好坚向设计，并与周边地块做好街接。室外地坪标高不低于<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>年一遇洪水位，井应比周边道路最低路段高程高出<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>米以上。</p>\n' +
          '<p>&nbsp; &nbsp; 2.周边道路控制标高:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>(详见附图)。地块内场地规划控制标高:<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>。</p>\n' +
          '<p>九、相关部门控制要求</p>\n' +
          '<p>&nbsp; &nbsp; 1.绿地率及相关绿化要求按照《宁波市城市绿化条例》及相关规范文件规定执行。</p>\n' +
          '<p>&nbsp; &nbsp; 2.按照国家、省、市关于海绵城市建设的有关要求，结合《宁波市海绵城市规划设计导则》(2017甬DX-13)相关规定，落实海绵城市规划设计内容。海绵城市各项强制性指标应符合《宁波市中心城区海绵城市专项规划(2016-2020) 》的要求，年径流总量控制率不低于___ %. 在施工图阶段进行专项审查，相关总图在竣工核实前报规划部门备案。</p>\n' +
          '<p>&nbsp; &nbsp; 3.地块建设应符合《宁波市人民政府办公厅关于推进新建住宅全装修工作的实施意见（试行)》(甬政办发{2018}39号）文件的要求。</p>\n' +
          '<p>&nbsp; &nbsp; 4.本地块建筑需要实施新型建筑工业化，其相关要求按照甬政办发{2015} 99 号、甬政办发{2016} 7号、甬政办发{2017} 30号等文件规定执行。</p>\n' +
          '<p>&nbsp; &nbsp; 5.地块建设应符合甬政办发{2017} 82号《宁波市人民政府办公厅关于印发宁波市电动汽车充电基础设施建设实施方案的通知》的文件规定。</p>\n' +
          '<p>&nbsp; &nbsp; 6.双语标识等要求按照相关文件规定执行。</p>\n' +
          '<p>设计方案中涉及海绵城市、工业化等以上内容及人防配套要求的，由相关行业主管部门在施工图阶段负责审查把关。</p>\n' +
          '<p>十、附图</p>\n' +
          '<p>&nbsp; &nbsp; 附1:地块位置示意图；附2:地块周边现状图；附3:地块周边相关规划示意图；附4:地块控制图；附5;地下空间利用圈，附6:城市设计及其它研究图。</p>\n' +
          '<p>十一、说明</p>\n' +
          '<p>&nbsp; &nbsp; 1.规划设计的基本图纸以自然资源和规划主管部门提供的1/500或1/0000地形图为准。</p>\n' +
          '<p>&nbsp; &nbsp; 2.用地面积以勘测定界成果为准。</p>\n' +
          '<p>&nbsp; &nbsp; 3.建筑密度、容积率、建筑高度为最大值。</p>\n' +
          '<p>&nbsp; &nbsp; 4.建筑面积计算规则按照浙江省工程建设标准《建筑工程建筑面积计算和竣工综合测量技术规程》 (DB33/T 1152-2018) 等相关规定执行。</p>\n' +
          '<p>&nbsp; &nbsp; 5.建筑工程规划电子报建材料应符合《宁波市建筑工程规划电子报建数据规则》要求。</p>\n' +
          '<p>&nbsp; &nbsp; 6.有日照分析要求的，应根据《宁波市建设项目日照分析技术规定》提交日照分析报告。</p>\n' +
          '<p>&nbsp; &nbsp; 7.本规划条件自核发之日起十二个月内未依法出让成交的，需重新出具。</p>\n' +
          '<p>&nbsp; &nbsp; 8.未尽事宜须符合有关规范和规定的要求。</p>\n' +
          '<p>&nbsp; &nbsp; 9.本规划条件由宁波市自然资源和规划局<span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>分局负责解释。</p>\n' +
          '<table style="border-collapse: collapse; width: 98.5141%; height: 74px; margin-left: auto; margin-right: auto;" border="1">\n' +
          '<tbody>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 14.2857%; text-align: center; height: 21px;" colspan="7"><span style="text-align: center;">地块控制指标表</span></td>\n' +
          '</tr>\n' +
          '<tr style="height: 21px;">\n' +
          '<td style="width: 14.2857%; text-align: center; height: 21px;"><span style="text-align: center;">地块编号</span></td>\n' +
          '<td style="width: 14.2857%; text-align: center; height: 21px;"><span style="text-align: center;">用地性质</span></td>\n' +
          '<td style="width: 14.2857%; text-align: center; height: 21px;"><span style="text-align: center;">用地面积(㎡)</span></td>\n' +
          '<td style="width: 14.2857%; text-align: center; height: 21px;"><span style="text-align: center;">容积率</span></td>\n' +
          '<td style="width: 14.2857%; text-align: center; height: 21px;"><span style="text-align: center;">建筑密度(%)</span></td>\n' +
          '<td style="width: 14.2857%; text-align: center; height: 21px;"><span style="text-align: center;">绿地率(%)</span></td>\n' +
          '<td style="width: 14.2857%; text-align: center; height: 21px;"><span style="text-align: center;">建筑高度(%)</span></td>\n' +
          '</tr>\n' +
          '<tr style="height: 15px;">\n' +
          '<td style="width: 14.2857%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 14.2857%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 14.2857%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 14.2857%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 14.2857%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 14.2857%; height: 21px;">&nbsp;</td>\n' +
          '<td style="width: 14.2857%; height: 21px;">&nbsp;</td>\n' +
          '</tr>\n' +
          '</tbody>\n' +
          '</table>\n' +
          '</td>\n' +
          '</tr>\n' +
          '</tbody>\n' +
          '</table>\n' +
          '<p style="text-align: right;"><span style="text-align: right;">宁波市自然资源和规划局分局</span><span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style="text-align: right;">编制&nbsp; &nbsp; 二〇二二年三月&nbsp; &nbsp;&nbsp;</span></p>'
    }
  },
  mounted() {
    setTimeout(() => {
      this.checkStatus()
    }, 1000)
  },
  methods: {
    getChangeData(res) {
      this.html = res
      console.log(res)
      this.$nextTick(() => {
        this.preview()
      })
    },
    print() {
      this.$refs.tinymceRefs.sendData()
      setTimeout(() => {
        print({
          printable: "forms",
          type: "html",
          scanStyles: false,
          css: "/static/sjfasc.css",
          documentTitle: "",
        });
      }, 10)
    },
    preview() {
      let obj1 = document.getElementById('1')
      let obj2 = document.getElementById('2')
      let obj3 = document.getElementById('3')
      let obj4 = document.getElementById('4')
      let obj5 = document.getElementById('5')
      let obj6 = document.getElementById('6')
      let obj7 = document.getElementById('7')
      let obj8 = document.getElementById('8')
      let obj9 = document.getElementById('9')
      let obj10 = document.getElementById('10')
      let obj11 = document.getElementById('11')
      let obj12 = document.getElementById('12')
      let obj13 = document.getElementById('13')
      let obj14 = document.getElementById('14')
      let obj15 = document.getElementById('15')
      let obj16 = document.getElementById('16')
      let obj17 = document.getElementById('17')
      let obj18 = document.getElementById('18')
      let obj19 = document.getElementById('19')
      let obj20 = document.getElementById('20')
      let obj21 = document.getElementById('21')
      let obj22 = document.getElementById('22')
      let obj23 = document.getElementById('23')
      obj1.checked = this.check1
      obj2.checked = this.check2
      obj3.checked = this.check3
      obj4.checked = this.check4
      obj5.checked = this.check5
      obj6.checked = this.check6
      obj7.checked = this.check7
      obj8.checked = this.check8
      obj9.checked = this.check9
      obj10.checked = this.check10
      obj11.checked = this.check11
      obj12.checked = this.check12
      obj13.checked = this.check13
      obj14.checked = this.check14
      obj15.checked = this.check15
      obj16.checked = this.check16
      obj17.checked = this.check17
      obj18.checked = this.check18
      obj19.checked = this.check19
      obj20.checked = this.check20
      obj21.checked = this.check21
      obj22.checked = this.check22
      obj23.checked = this.check23
    },
    checkStatus() {
      let that = this
      let obj11 = document.getElementById('editors_ifr').contentDocument.getElementById('1')
      let obj12 = document.getElementById('editors_ifr').contentDocument.getElementById('2')
      let obj13 = document.getElementById('editors_ifr').contentDocument.getElementById('3')
      let obj14 = document.getElementById('editors_ifr').contentDocument.getElementById('4')
      let obj15 = document.getElementById('editors_ifr').contentDocument.getElementById('5')
      let obj16 = document.getElementById('editors_ifr').contentDocument.getElementById('6')
      let obj17 = document.getElementById('editors_ifr').contentDocument.getElementById('7')
      let obj18 = document.getElementById('editors_ifr').contentDocument.getElementById('8')
      let obj19 = document.getElementById('editors_ifr').contentDocument.getElementById('9')
      let obj110 = document.getElementById('editors_ifr').contentDocument.getElementById('10')
      let obj111 = document.getElementById('editors_ifr').contentDocument.getElementById('11')
      let obj112 = document.getElementById('editors_ifr').contentDocument.getElementById('12')
      let obj113 = document.getElementById('editors_ifr').contentDocument.getElementById('13')
      let obj114 = document.getElementById('editors_ifr').contentDocument.getElementById('14')
      let obj115 = document.getElementById('editors_ifr').contentDocument.getElementById('15')
      let obj116 = document.getElementById('editors_ifr').contentDocument.getElementById('16')
      let obj117 = document.getElementById('editors_ifr').contentDocument.getElementById('17')
      let obj118 = document.getElementById('editors_ifr').contentDocument.getElementById('18')
      let obj119 = document.getElementById('editors_ifr').contentDocument.getElementById('19')
      let obj120 = document.getElementById('editors_ifr').contentDocument.getElementById('20')
      let obj121 = document.getElementById('editors_ifr').contentDocument.getElementById('21')
      let obj122 = document.getElementById('editors_ifr').contentDocument.getElementById('22')
      let obj123 = document.getElementById('editors_ifr').contentDocument.getElementById('23')
      obj11.onclick = function () {
        that.check1 = !that.check1
      }
      obj12.onclick = function () {
        that.check2 = !that.check2
      }
      obj13.onclick = function () {
        that.check3 = !that.check3
      }
      obj14.onclick = function () {
        that.check4 = !that.check4
      }
      obj15.onclick = function () {
        that.check5 = !that.check5
      }
      obj16.onclick = function () {
        that.check6 = !that.check6
      }
      obj17.onclick = function () {
        that.check7 = !that.check7
      }
      obj18.onclick = function () {
        that.check8 = !that.check8
      }
      obj19.onclick = function () {
        that.check9 = !that.check9
      }
      obj110.onclick = function () {
        that.check10 = !that.check10
      }
      obj111.onclick = function () {
        that.check11 = !that.check11
      }
      obj112.onclick = function () {
        that.check12 = !that.check12
      }
      obj113.onclick = function () {
        that.check13 = !that.check13
      }
      obj114.onclick = function () {
        that.check14 = !that.check14
      }
      obj115.onclick = function () {
        that.check15 = !that.check15
      }
      obj116.onclick = function () {
        that.check16 = !that.check16
      }
      obj117.onclick = function () {
        that.check17 = !that.check17
      }
      obj118.onclick = function () {
        that.check18 = !that.check18
      }
      obj119.onclick = function () {
        that.check19 = !that.check19
      }
      obj120.onclick = function () {
        that.check20 = !that.check20
      }
      obj121.onclick = function () {
        that.check21 = !that.check21
      }
      obj122.onclick = function () {
        that.check22 = !that.check22
      }
      obj123.onclick = function () {
        that.check23 = !that.check23
      }

    },
  }
}
</script>

<style scoped>
#forms {
  display: none;
}


</style>
